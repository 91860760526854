html,
body {
  width: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

header,
main,
footer {
  flex-shrink: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
